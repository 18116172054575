import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import ProductCardWithImageTitleAndDesc from "@molecules/ProductCardWithImageTitleAndDesc";
import Layout from "@organisms/Layout";
import { useTranslation } from "react-i18next";
import { withPrefix } from "gatsby";
import Header2 from "@atoms/Header2";
import RoundedLink from "@atoms/rounded_link/RoundedLink";

const IndexPage = () => {
  const { t } = useTranslation("index");

  return (
    <Layout>
      <Helmet>
        <title>BARAKA | {t("Home")}</title>
      </Helmet>
      <main className="flex flex-col items-center mx-auto">
        <div
          style={{
            backgroundSize: "cover",
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${withPrefix("/images/main-pic.webp")})`,
          }}
          className="relative h-screen w-full flex flex-col justify-center items-center mb-5 md:mb-0"
        >
          <div className="z-10 text-center">
            <h1 className="text-center text-white mb-3">
              {t("Lebensmittel - unsere Passion")}
            </h1>
            <h3 className="text-sm md:text-2xl text-white font-light mb-3">
              {t("Ihr Handelspartner für Hartweizengrieß, Bulgur und Couscous")}
            </h3>
            <div
              style={{
                maxWidth: 200,
              }}
              className="font-bold mx-auto text-center"
            >
              <RoundedLink isActive href="/products">
                {t("Unsere Produkte")}
              </RoundedLink>
            </div>
          </div>
          <div className="absolute w-full h-full bg-black opacity-20" />
        </div>

        <div className="md:h-screen w-full flex flex-col md:justify-center md:items-center">
          <Header2 content={t("Unsere Produkte")} />
          <div
            style={{
              maxWidth: 1600,
            }}
            className="w-full px-2 md:flex md:justify-center"
          >
            <ProductCardWithImageTitleAndDesc
              paddingLeftTwcss={"md:pl-24"}
              img={
                <StaticImage
                  className="rounded-xl"
                  src="../assets/images/hwg.png"
                  alt="Baraka Hartweizengriess"
                  imgStyle={{
                    backgroundColor: "#E5E5E5",
                  }}
                />
              }
              title={t("Hartweizengrieß")}
            />

            <ProductCardWithImageTitleAndDesc
              paddingLeftTwcss={"md:pl-12"}
              paddingRightTwcss={"md:pr-12"}
              img={
                <StaticImage
                  className="rounded-xl"
                  src="../assets/images/hartweizengriess.png"
                  alt="Baraka Hartweizengriess"
                  imgStyle={{
                    backgroundColor: "#E5E5E5",
                  }}
                />
              }
              title={t("Couscous")}
            />

            <ProductCardWithImageTitleAndDesc
              paddingRightTwcss={"md:pr-24"}
              img={
                <StaticImage
                  className="rounded-xl"
                  src={`../assets/images/bulgur.png`}
                  alt="Baraka Hartweizengriess"
                  imgStyle={{
                    backgroundColor: "#E5E5E5",
                  }}
                />
              }
              title={t("Bulgur")}
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["header", "footer", "index"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

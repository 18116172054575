import RoundedLink from "@atoms/rounded_link/RoundedLink";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  img?: any;
  paddingLeftTwcss?: string;
  paddingRightTwcss?: string;
};

const ProductCardWithImageTitleAndDesc: React.FC<Props> = ({
  img,
  title,
  paddingLeftTwcss,
  paddingRightTwcss,
}) => {
  const { t } = useTranslation("index");
  return (
    <div
      className={`w-full my-4 sm:w-1/3 ${paddingLeftTwcss} ${paddingRightTwcss}`}
    >
      <div className="mb-7">{img}</div>
      <div
        style={{
          height: 2,
          backgroundColor: "#F7A600",
        }}
        className="w-full mb-5 md:w-4/5"
      />
      <h2 className="text-black font-bold text-left mb-5">{title}</h2>
      <div className="w-full md:w-1/2 max-w-xs font-bold">
        <RoundedLink isActive href="/products">
          {t("Mehr erfahren")}
        </RoundedLink>
      </div>
    </div>
  );
};

export default ProductCardWithImageTitleAndDesc;
